/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useSalesforcePersonalizedContentQuery from '../useSalesforcePersonalizedContentQuery';

export const useInterstitialPersonalization = (brand) => {
    let isNewCheckoutInterstitialEnabled = false;
    const ffIsNativeInterstitialPageEnabled = useSelector(getFeatureFlag('is-native-interstitial-page-enabled'));
    if (!ffIsNativeInterstitialPageEnabled) return isNewCheckoutInterstitialEnabled;
    const { loading, data, error } = useSalesforcePersonalizedContentQuery({
        queryName: 'checkoutInterstitial',
        interactionName: `${brand?.domain} - Get Campaign - Interstitial`,
        featureFlag: ffIsNativeInterstitialPageEnabled,
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
    });

    if ((loading) || (!loading && !data && !error)) {
        return isNewCheckoutInterstitialEnabled;
    }

    if (error) {
        mbpLogger.logError({
            function: 'NativeInterstitialABTest',
            appName: process.env.npm_package_name,
            module: 'mbp-checkout',
            message: 'Native Interstitial Salesforce ABTest returned an error',
            jsError: error,
        });
    }

    const userGroup = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    isNewCheckoutInterstitialEnabled = userGroup?.toLowerCase?.() === 'test';
    if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.setItem('checkoutInterstitialData', data?.contentSF ? JSON.stringify(data?.contentSF) : '{}');
        window.sessionStorage.setItem('isNewCheckoutInterstitialEnabled', isNewCheckoutInterstitialEnabled);
    }
    return isNewCheckoutInterstitialEnabled;
};

export default useInterstitialPersonalization;
